import { FC } from 'react';
import Image from 'next/image';

const metrics = [
  {
    id: 1,
    stat: '100+',
    emphasis: 'Residential Projects',
  },
  {
    id: 2,
    stat: '100+',
    emphasis: 'Commercial Projects',
  },
  {
    id: 3,
    stat: '100%',
    emphasis: 'Customer satisfaction',
  },
];

const Metrics: FC = () => (
  <div className="relative bg-gray-900">
    <div className="absolute inset-x-0 bottom-0 h-80 xl:top-0 xl:h-full">
      <div className="h-full w-full xl:grid xl:grid-cols-2">
        <div className="h-full xl:relative xl:col-start-2">
          <div className="relative h-full w-full">
            <Image
              className="object-cover opacity-25 xl:absolute xl:inset-0"
              src="https://images.unsplash.com/photo-1622789095096-987debfe7a3c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80"
              alt="Outdoor lighting hung outside"
              fill
            />
          </div>
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
          />
        </div>
      </div>
    </div>
    <div className="mx-auto max-w-4xl px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-flow-col-dense xl:grid-cols-2 xl:gap-x-8">
      <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
        <h2 className="text-sm font-semibold uppercase tracking-wide">
          <span className="bg-gradient-to-r from-purple-300 to-indigo-300 bg-clip-text text-transparent">
            Our Metrics
          </span>
        </h2>
        <p className="mt-3 text-3xl font-extrabold text-white">
          Some important facts
        </p>
        <p className="mt-5 text-lg text-gray-300">
          HLB Electric takes pride in offer both residential and commercial
          services and take customer satisfaction as one of our top priorities.
        </p>
        <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
          {metrics.map((item) => (
            <p key={item.id}>
              <span className="block text-2xl font-bold text-white">
                {item.stat}
              </span>
              <span className="mt-1 block text-base text-gray-300">
                <span className="font-medium text-white">{item.emphasis}</span>
              </span>
            </p>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default Metrics;
